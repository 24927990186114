import { Injectable, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  public renderer: Renderer2;

  public currencyMapper = {
    "USD": "$",
    "AUD": "A$",
    "SGD": "S$",
    "EUR": "€",
    "YEN": "¥",
    "IDR": "Rp",
    "INR": "₹",
    "GBP": "£",
    "AED": "د.إ",
    "Philipines": "₱",
    "RM Malaysia": 'RM',
    "Rs Srilanka": 'Rs',
    "Thai Bath": "฿",
  }

  constructor(private http: HttpClient) { }

  callRestful(type: string, url: string, body?: any, params?: HttpParams): Observable<any> {

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Credentials', 'true');
    headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, TRACE, OPTIONS, PATCH');
    headers.append('responseType', 'json');



    switch (type) {
      case 'GET':
        return this.http.get(url,
          {
            responseType: 'json',
            params: params

          })
          .pipe(
            catchError(this.catchError.bind(this))
          );
      case 'JSON':
        return this.http.get(url,
          {
            responseType: 'text',
            params: params

          })
          .pipe(
            catchError(this.catchError.bind(this))
          );
      case 'POST':
        return this.http.post<any>(url, body, { headers: headers })
          .pipe(
            catchError(this.catchError.bind(this))
          );
      case 'GET_MSN':
        return this.http.get(url, { headers: headers })
          .pipe(
            catchError(this.catchError.bind(this))
          );
      case 'DELETE':
        return this.http.get(url,
          {
            responseType: 'json',
            params: params

          })
          .pipe(
            catchError(this.catchError.bind(this))
          );

      default:
        console.log('Debug at type request ' + type);
        return null;
    }
  }
  catchError(err) {
    console.log(err);
    if (err.message) {
      //alert("Error occurred, " + err.message + " try after some time");
    } else {
      //alert("Network error accouured");
    }
  }

  private handleError(error: Response | any, isNotError?: boolean, url?): Observable<Response> {
    if (!isNotError && !error.ok) {
      if (error.status == 0) { }
      //    this.showMessage("error", "Unable to process your request, please try again after some time.");
      //  else if(error.status==502) this.showMessage("error", "Something went wrong..Please contact GreenGST team!");
      //  else if(error.status==500) this.showMessage("error", "Something went wrong..Please contact GreenGST team.");
      else if (error.status == 400) {
        var msg = error.json() || '';
        //    this.showMessage("error", msg && msg['error_msg'] ? msg['error_msg'] : "Something went wrong..Please contact GreenGST team.");
      }
      //   else this.showMessage("error", "Something went wrong..Please contact GreenGST team...");
      //  this.pService.done();
      // this.logError(error,url);
    }
    let errMsg: string;
    // TODO error message on screen
    if (error instanceof Response) {
      const body = error.json() || '';
      //  const err = body.error || JSON.stringify(body);
      //  errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return Observable.throw(errMsg);
  }
  getCurrencySign(currency: any) {
    return currency ? this.currencyMapper[currency] : '';
  }
  regionList = ["North", "Gujarat", "South", "East", "Mumbai", "Enterprise"];
  mexicoRegionList = ["Mexico", "North", "South", "East", "Enterprise","Gujarat","Mumbai"];
  numDifferentiation(value) {
    let val = Math.abs(value);
    let result = null;
    if (val >= 10000000) {
      result = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      result = (val / 100000).toFixed(2) + ' Lac';
    } else if (val >= 1000) {
      result = (val / 1000).toFixed(2) + ' K';
    }
    return result || val;
  }

}
